
































import { Component, Vue } from 'vue-property-decorator'
import { getUserInfo, startExam } from '@/api/student'
import { UserModule } from '@/store/modules/user'
import { ExamRecord, ExamResult, Student } from '@/api/type'
import { ElTable } from 'element-ui/types/table'

@Component({})
export default class Home extends Vue {
  student: null | ExamRecord = null
  rows: ExamRecord[] = []

  created() {
    this.fetch()
  }

  fetch() {
    getUserInfo().then((rsp) => {
      this.student = rsp.data as ExamRecord
      this.rows.push(this.student)
      if (this.rows.length > 0) {
        const table = this.$refs['table'] as ElTable
        table.setCurrentRow(this.rows[0])
        console.log('set row ')
      }
    })
  }

  start() {
    console.log('开始')
    startExam().then(() => this.$router.push('/exam'))
  }

  logout() {
    UserModule.LogOut().then(() => this.$router.push(`/login`))
  }
}
